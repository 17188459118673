import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "ModalImageFull" }
const _hoisted_2 = { class: "ModalImageFull--Content" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "LegalAdvert" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    style: _normalizeStyle(_ctx.themeProps)
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "ModalImageFull--Close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enlargePic( null )))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("div", { class: "icon" }, null, -1)
        ])),
        _createElementVNode("img", {
          class: "Modal--image",
          src: _ctx.enlargedPic
        }, null, 8, _hoisted_3)
      ])
    ], 512), [
      [_vShow, Boolean(_ctx.enlargedPic)]
    ]),
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('community_copyright_wl')), 1),
      _cache[4] || (_cache[4] = _createElementVNode("span", null, "|", -1)),
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {$event.preventDefault();_ctx.$router.push({name:'conditions'})})
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('community_terms_policy')), 1)
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("span", null, "|", -1)),
      _createElementVNode("a", {
        href: _ctx.getUrlLegal,
        target: "_blank"
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('community_legal_warning')), 1)
      ], 8, _hoisted_5),
      (_ctx.theme.privacyPolicyActive)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "|"))
        : _createCommentVNode("", true),
      (_ctx.theme.privacyPolicyActive)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: "#",
            onClick: _cache[2] || (_cache[2] = ($event: any) => {$event.preventDefault();_ctx.$router.push({name:'privacy_policy'})})
          }, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('community_privacy_policy')), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}